import CdnImage from '@/components/atoms/CdnImage/CdnImage';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import { NotificationAlertState } from '@petcolove/lost--client--api-sdk/dist/concrete/sdks/services/websocket/uiNotifications/interfaces';
import { formatDistanceStrict } from 'date-fns';
import Link from 'next/link';

/**
 * INotificationRow
 *
 * @interface
 */
export interface INotificationRow {
  /** The Id of the notification alert */
  alertId: string;
  /** The Image to be displayed on the notification */
  image: string;
  /** The headline text to be displayed on the notification */
  headline: string;
  /** The label of the link */
  linkLabel: string;
  /** The URL of the link */
  linkUrl: string;
  /** The date that this notification was sent */
  date?: Date;
  /** The state of the notification */
  state: NotificationAlertState;
  /** The callback onClick event */
  onClick?: (alertId: string, state: NotificationAlertState) => void;
  /** Close the notification drawer */
  closeDrawer?: () => void;
  /** Event Type */
  eventType?: string;
}

/** This is the path to the match results page */
const matchResultsPath = '/dash/match-results';

/**
 * Determine the path to use with the notification link
 *
 * @param {string} linkUrl - The URL to use with the notification link
 * @param {string} eventType - The type of search that resulted in the
 *   notification
 * @returns {string} - The path to use with the notification link
 */
export const getLinkPath = (linkUrl: string, eventType?: string) => {
  if (eventType && eventType === 'persistentSearchMicrochipMatch') {
    return linkUrl;
  }
  return matchResultsPath + linkUrl;
};

/**
 * NotificationRow A row component used to display notifications
 *
 * @param {INotificationRow} props - The props for the NotificationRow component
 * @returns {React.FC<INotificationRow>} Component
 */
const NotificationRow: React.FC<INotificationRow> = ({
  alertId,
  image,
  headline,
  linkLabel,
  linkUrl,
  date,
  state,
  onClick,
  closeDrawer,
  eventType,
}) => {
  return (
    <div data-testid="notification-row">
      <Link href={getLinkPath(linkUrl, eventType)} className="w-full">
        <a
          target="_blank"
          className="group"
          onClick={() => {
            onClick?.(alertId, state);
            closeDrawer?.();
          }}
          data-testid={`notification-row-cta-${alertId}`}
        >
          <div className="flex flex-row py-4">
            {(state === 'unread' || state === 'unseen') && (
              <div className="mr-3 h-[71px] bg-base-300 w-1 rounded-full"></div>
            )}
            <div className="w-[71px] h-[71px] relative rounded-lg overflow-hidden">
              <CdnImage
                src={image}
                width={71}
                height={71}
                className="group-hover:transform group-hover:scale-110 transition-all duration-200 object-cover"
              />
            </div>
            <div className="flex flex-col justify-center pl-4 space-y-[3.5px] flex-shrink">
              <Paragraph size="body5" styling="bold">
                {headline}
              </Paragraph>
              <span className="text-[14px] leading-[22px] underline visited:text-base-300 group-hover:text-base-300">
                {linkLabel}
              </span>
              {date && (
                <Paragraph
                  data-testid="delta-time"
                  size="overline"
                  className="text-neutral-600"
                >
                  {formatDistanceStrict(date, new Date())}
                </Paragraph>
              )}
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
};

export default NotificationRow;
